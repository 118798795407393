import { useMobile } from 'src/hooks/useMobile'

interface ItemInformationProps {
  additionalInformation: {
    color?: string
    size?: string
  }
}

export const ItemInformation = ({
  additionalInformation,
}: ItemInformationProps) => {
  const { isMobile } = useMobile()

  if (!additionalInformation) {
    return <></>
  }

  const { color, size } = additionalInformation
  const sizeNormalized = size
    ?.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  const haveSizeVariation = size && sizeNormalized !== 'unico'

  return (
    <div className="text-xs text-tertiary flex flex-col sm:gap-1 sm:flex-row">
      <span>{color ? `Cor: ${color}` : ''}</span>
      {!isMobile && haveSizeVariation && ' | '}
      <span>{haveSizeVariation ? `Tamanho: ${size}` : ''}</span>
    </div>
  )
}
