export function CheckCircle() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 3.5498C7.31402 3.5498 3.5498 7.31402 3.5498 11.9998C3.5498 16.6856 7.31402 20.4498 11.9998 20.4498C16.6856 20.4498 20.4498 16.6856 20.4498 11.9998V11.2998H21.9498V11.9998C21.9498 17.514 17.514 21.9498 11.9998 21.9498C6.48559 21.9498 2.0498 17.514 2.0498 11.9998C2.0498 6.48559 6.48559 2.0498 11.9998 2.0498C14.0344 2.0498 15.8919 2.5868 17.4357 3.6895L16.5639 4.9101C15.3077 4.01281 13.7652 3.5498 11.9998 3.5498ZM21.9301 6.03013L12.4301 15.5301C12.1577 15.8026 11.7987 15.8998 11.4998 15.8998C11.2009 15.8998 10.842 15.8026 10.5695 15.5301L7.26947 12.2301L8.33013 11.1695L11.4998 14.3391L20.8695 4.96947L21.9301 6.03013Z"
        fill="#101010"
      />
    </svg>
  )
}
