import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { getCookie } from 'src/utils/getCookie'
import Icon from 'src/components/ui/Icon'
import { useMobile } from 'src/hooks/useMobile'
import { useState } from 'react'
import { SlideOverDefault } from 'src/components/ui/SlideOverDefault/SlideOverDefault'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { CheckCircle } from 'src/components/Icons/CheckCircle'
import Cookies from 'js-cookie'

import storeConfig from '../../../../../store.config'
import GoToShippingSkeleton from './GoToShippingSkeleton'

const userDataCookie = getCookie('memberAuthToken')

const GoToShipping = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const { isLoading, orderForm } = useCheckout()
  const { isMobile } = useMobile()

  const checkoutUrl = `${storeConfig.checkoutUrl}?orderFormId=${orderForm.orderFormId}/#shipping`

  const loginUrl = `${storeConfig.loginUrl}?returnUrl=${encodeURIComponent(
    checkoutUrl
  )}`

  const disableButton = orderForm.items.some(
    (item) => item.availability !== 'available'
  )

  const standardObject = getStandardObject()

  function trackEvent(
    eventAction: string,
    eventPage: string,
    eventCategory: string
  ) {
    const event = makeEventTrack({
      eventAction,
      eventPage,
      eventCategory,
    })

    sendEvent(event)
  }

  function sendAmplitudeEvent() {
    dispatchAmplitudeEvent({
      eventName: 'Account Process Entered',
      eventData: {
        ...standardObject,
      },
    })
  }

  const handleSubmit = async () => {
    if (userDataCookie) {
      window.location.href = checkoutUrl

      return
    }

    setIsSideMenuOpen(true)
    trackEvent('Continuar para entrega', 'carrinho', 'clique')
  }

  if (isLoading && !isMobile) {
    return <GoToShippingSkeleton />
  }

  return (
    <>
      <div className="sm:my-8 fixed sm:relative left-0 bottom-0 sm:left-auto sm:bottom-auto p-4 sm:p-0 w-full bg-[#fff] z-20 shadow-dropCard sm:shadow-none">
        <div className="flex items-center sm:hidden mb-[14px] ">
          <span className="text-xl font-medium text-restructure-primary">
            Total: {orderForm.totalizers.total}
          </span>
        </div>
        <button
          className={`h-[48px] w-full mb-7 sm:mb-0 sm:h-[64px] flex bg-restructure-background-action-1 text-restructure-primary-inverted rounded-round justify-center items-center ${
            disableButton
              ? 'pointer-events-none opacity-50'
              : 'pointer-events-auto'
          }`}
          onClick={handleSubmit}
        >
          <span className="desktop-body-semibold-text1 mr-2 font-inter text-base sm:text-lg font-semibold">
            Continuar para entrega
          </span>
          <Icon width={24} height={24} name="chevron-right-white" />
        </button>
      </div>

      <SlideOverDefault
        position={isMobile ? 'bottom' : 'right'}
        isOpen={isSideMenuOpen}
        onClose={() => setIsSideMenuOpen(false)}
      >
        <div className="px-8 sm:px-12 flex flex-col gap-10 font-inter text-restructure-primary sm:gap-8">
          <div>
            <span className="text-xl sm:text-2xl font-semibold mb-2">
              Compra rápida
            </span>
            <p className="mt-2 text-sm sm:text-base">
              Acumule Cashback sem a necessidade de criação de conta
            </p>

            <p className="flex gap-2 font-inter text-sm sm:text-base font-normal my-6">
              <CheckCircle /> Compre utilizando apenas o e-mail
            </p>

            <button
              className="text-white text-base sm:text-lg w-full font-semibold w-ful py-3 bg-restructure-background-action-1 rounded-full sm:py-[18.5px]"
              onClick={async () => {
                sendAmplitudeEvent()

                window.location.href = checkoutUrl
              }}
            >
              Continuar
            </button>
          </div>
          <div>
            <span className="text-xl sm:text-2xl font-semibold">
              Faça login
            </span>

            <p className="mt-2 text-sm sm:text-base">
              Ao criar sua conta, você acumula cashback e se torna membro do
              Clube, com acesso a benefícios exclusivos.
            </p>

            <p className="flex gap-2 font-inter text-sm sm:text-base font-normal my-6">
              <CheckCircle /> Pagamento com cashback
            </p>
            <button
              className="w-full font-semibold text-base sm:text-lg py-3 rounded-full border border-lightGray sm:py-[18.5px]"
              onClick={async () => {
                Cookies.set('loginSuccess', 'true', {
                  path: '/',
                  domain: '.decathlon.com.br',
                })

                window.location.href = loginUrl
              }}
            >
              Fazer login
            </button>
          </div>
        </div>
      </SlideOverDefault>
    </>
  )
}

export default GoToShipping
